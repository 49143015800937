import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const keypointTree = (subjectCourseId) => {
  return ajax.get(
    `${base}/api/itedu/v1/tiku/keypoint/tree?subjectCourseId=${subjectCourseId}`
  );
};

const updateKeypoint = (node) => {
  return ajax.post(`${base}/api/itedu/v1/tiku/keypoint/update-keypoint`, node);
};

const deleteKeypoint = (node) => {
  return ajax.post(`${base}/api/itedu/v1/tiku/keypoint/delete-keypoint`, node);
};

export const keypointApi = {
  keypointTree: keypointTree,
  updateKeypoint,
  deleteKeypoint,
};
