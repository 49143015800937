<template>
  <section>
    <div class="custom-tree-container">
      <div class="block">
        <el-form label-width="80px" style="min-width:500px">
          <el-form-item label="课程ID">
            <el-input v-model="subjectCourseId" placeholder="TODO"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onQueryKeypoint">查询</el-button>
            <el-button @click="onQueryKeypoint">刷新</el-button>
          </el-form-item>
        </el-form>
        <div class="header-tool">
          <el-button type="primary" @click="addRootKeypoint">添加顶级考点</el-button>
        </div>
        <el-tree :data="keypointList" node-key="id" :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span class="node-tool">
              <el-button type="text" size="mini" @click="() => edit(data)">
                编辑
              </el-button>
              <el-button type="text" size="mini" @click="() => append(data)">
                添加
              </el-button>
              <el-button type="text" size="mini" @click="() => remove(node, data)">
                删除
              </el-button>
              <span> id: {{ data.id }} </span>
            </span>
          </span>
        </el-tree>
        <el-dialog title="请输入考点名称" :visible.sync="dialogVisible" width="30%" class="edit-form">
          <div class="row">
            <span class="edit-label">考点名称:</span
            ><el-input class="edit-input" v-model="currentLabel" placeholder="考点名称"></el-input>
          </div>
          <div class="row">
            <span class="edit-label">父节点id:</span
            ><el-input class="edit-input" v-model="currentParentId" placeholder="父节点id"></el-input>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </section>
</template>

<script>
import { keypointApi } from "@/api/keypointApi";
export default {
  data() {
    return {
      dialogVisible: false,
      currentData: { id: 0, label: "", children: {}, parentId: 0 },
      currentLabel: "",
      currentParentId: 0,
      isEdit: 0, // 0 edit 1 append 2 addRoot
      subjectCourseId: 0,
      keyword: "",
      keypointList: [],
      editNode: { id: 0, label: "", children: {}, parentId: 0 },

      dfsFindParentItem: null,
    };
  },

  methods: {
    append(data) {
      this.isEdit = 1;
      this.currentData = data;

      this.currentLabel = "";
      this.currentParentId = data.id;
      console.log("append, current parent", this.currentData, this.currentLabel, this.currentParentId);

      this.dialogVisible = true;
    },
    addRootKeypoint() {
      this.isEdit = 2;
      this.currentLabel = "";
      this.currentParentId = 0;
      this.dialogVisible = true;
    },

    edit(data) {
      console.log("edit", data);
      this.isEdit = 0;
      this.currentData = data;
      this.currentLabel = data.label;
      this.currentParentId = data.parentId;
      this.dialogVisible = true;
    },

    remove(node, data) {
      const editNode = { subjectCourseId: this.subjectCourseId, id: data.id };
      keypointApi
        .deleteKeypoint(editNode)
        .then((ret) => {
          if (ret.code == 0) {
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex((d) => d.id === data.id);
            children.splice(index, 1);
            this.$message({
              message: "删除成功",
              type: "success",
            });
          } else if (ret.msg) {
            this.$message.error(ret.msg);
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    confirm() {
      if (this.isEdit === 0) {
        // edit mode
        this.editNode = {
          id: this.currentData.id,
          label: this.currentLabel,
          parentId: this.currentParentId,
          subjectCourseId: this.subjectCourseId,
        };
        keypointApi.updateKeypoint(this.editNode).then((ret) => {
          if (ret.code == 0) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            if (this.currentData.parentId == ret.data.parentId) {
              this.currentData.label = ret.data.title;
            } else {
              this.onQueryKeypoint();
            }
          } else if (ret.msg) {
            this.$message.error(ret.msg);
          } else {
            this.$message.error("保存失败");
          }
        });
      } else if (this.isEdit === 1) {
        // append mode
        const newChild = { id: 0, label: this.currentLabel, children: [], parentId: this.currentData.id };
        if (!this.currentData.children) {
          this.$set(this.currentData, "children", []);
        }
        this.editNode = {
          id: 0,
          subjectCourseId: this.subjectCourseId,
          parentId: this.currentData.id,
          label: this.currentLabel,
        };
        keypointApi.updateKeypoint(this.editNode).then((ret) => {
          if (ret.code == 0) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            newChild.id = ret.data.id;
            console.log(newChild);
            this.currentData.children.push(newChild); // 新加的节点push进来
          } else {
            this.$message.error("保存失败");
          }
        });
      } else if (this.isEdit === 2) {
        // add root node mode
        const newChild = { id: 0, label: this.currentLabel, children: [], parentId: 0 };
        this.editNode = {
          id: 0,
          subjectCourseId: this.subjectCourseId,
          parentId: 0,
          label: this.currentLabel,
        };
        keypointApi.updateKeypoint(this.editNode).then((ret) => {
          if (ret.code == 0) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            newChild.id = ret.data.id;
            this.keypointList.push(newChild);
          } else {
            this.$message.error("保存失败");
          }
        });
      }
      this.dialogVisible = false;
    },
    onQueryKeypoint() {
      keypointApi.keypointTree(this.subjectCourseId).then((ret) => {
        if (ret) {
          console.log("...", ret);
          this.keypointList = ret;
        }
      });
    },
    // dfsFindParent(targetPid) {
    //   for (let item of this.keypointList) {
    //     this.dfs(targetPid, item);
    //   }
    // },
    // dfs(targetPid, root) {
    //   if (this.dfsFindParentItem != null) {
    //     return;
    //   }
    //   if (root.id == targetPid) {
    //     this.dfsFindParentItem = root;
    //     return;
    //   }
    //   if (root.children != null && root.children.length > 0) {
    //     for (let item of root.children) {
    //       this.dfs(targetPid, item);
    //     }
    //   }
    //   return;
    // },
  },
};
</script>

<style lang="less" scoped>
.header-tool {
  margin: 10px 0;
}
.node-tool {
  margin: 0 20px;
}
.custom-tree-container {
  .block {
    .edit-form {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px;
        .edit-input {
          margin: 0px 10px;
        }
        .edit-label {
          width: 100px;
        }
      }
    }
  }
}
</style>
